/* You can add global styles to this file, and also import other style files */

:root {
    --viewport-height:100%;
    --cPrincipalClaro: #ebf8ff;
    --cIconInput: rgba(176, 190, 197, 0.8);
    --cFondoInput: transparent;
    --cPrincipal: #00284b;
    --cPrincipalSelect: rgba(0, 40, 25, 0.16);
    --cPrincipalClaro2: rgba(0, 40, 25, 0.05);
    --cSecundarioClaro2:#addde3;
    --cSecundarioClaro:#79ced2;
    --cSecundario: rgb(55, 168, 181);
    --cSecundarioSelect: rgba(132, 212, 200, 0.1);
    --cTerciario:rgb(255, 193, 7);
    --cTerciarioClaro:rgba(255, 193, 7,.1);
    --cCuartario:	rgba(1, 98, 117);
    --cCuartarioClaro:	rgba(1, 98, 117,.1);
    --cBase: #dae5eb;
    /* --cBase: #F1F5F9; */
    --cRowDif: rgba(241, 245, 249,.3);
    --cloginTitulo: #0f1b2e;
    --cGray: #6c757d;
    --cBtn: #f2f2f2;
    --cMainMenu: #00284b;
    --cSelectMainMenu: #37a8b5;
    --cGrisClaro: rgb(148, 152, 155);
    --cFont: #607d8b;
    --cMuted:rgba(176, 190, 197, 0.8);
    /* --cMuted:rgba(176, 190, 197, 0.8);; */
    --cFontTitulo: #333333;
    --cFontOscuro: #333333;
    --cSelectSubMenu: #eaeaea;
    --cSeparadores: #efefef;
    --cDanger: #dc3545;
    --cSuccess:#02be9d;
    --cWarning:#ffc107;
    --hHeader: 75px;
    --wMenuClose: 55px;
    --wMenuOpen: 250px;
    --fzMenu: 1.8rem;
    --fzTitulos: 1.25rem;
    --fzGeneral: 0.9rem;
    --fzTitulosInside: 1rem;
    --pGeneral: 30px;
    --pGeneralLite: 15px;
    --mIcono: 0.5rem;
    --mMenuContentClose: calc(-250px + 55px);
    --mMenuContentCloseXS: calc(-250px);
    --pCardGeneral: 1.5rem;
    --espacioGeneralV: 1rem;
    --espacioGeneralH: 1.25rem;
    --cInputLabelHover:rgb(21,27,38);
    --inputColor: #607d8b;
    --font:400 14px/1.25  -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Helvetica, Arial, sans-serif;
    --chart1LeftPda:rotate(0deg);
    --chart1RightPda:rotate(0deg);
    --bs-warning-text-emphasis:rgb(255,218,106);
    --bs-warning-text-emphasis-rgb:255,218,106;
    --bs-success-text-emphasis:#75b798;
    --bs-success-text-emphasis-rgb:117,183,152;
    --bs-danger-text-emphasis:#ea868f;
    --bs-danger-text-emphasis-rgb:234,134,143;
}

@font-face {
    font-family: "Segoe UI Mono";
    src: url(./assets/fonts/segoe-ui-mono.ttf) format("truetype");
}

::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}

::-webkit-scrollbar-thumb {
    background: rgba(207, 216, 220, 0.8);
    border-radius: 16px;
}

html{
    position: fixed;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.x-disabled{
    pointer-events: none;
    cursor: default !important;
}

.cBlack{
    color:#333333;
}




.btn-outline-dash{
    color: var(--cSecundario);
    border-color: var(--cSecundario);
}

.btn-check:checked+.btn-outline-dash{
    color:#fff;
    background-color: var(--cSecundario);
    border-color: var(--cSecundario);
}

.btn-check:focus+.btn, .btn:focus {
    outline: 0;
    box-shadow:none;
}


.listAdjunto .item-adjunto{
    border: 1px solid var(--cSeparadores);
    text-align: center;
    padding: 20px;
}

.font-mono{
    /* font:400 14px/1.25 ui-monospace,SFMono-Regular,Menlo,Monaco,Consolas,liberation mono,courier new,monospace !important; */
    font:400 14px/1.25 'Segoe UI Mono' !important;
    letter-spacing: -0.7px;
}

.select-item-fda-portal{
    border-right: 2px solid var(--cPrincipal);
}

.accionesSof{
    position: absolute;
    right: 10px;
    display: flex;
    width: 100px;
    justify-content: flex-end;
}
.itemsSof.error{
    border-color: var(--cDanger) !important;
}

.hidden{
    visibility: hidden;
    position: absolute;
}

.separadorVertical{
    width: 3px;
    height: 50px;
    background-color: #000;
}

.main_book .main_info_general{
    line-height: 1.5;
}
.main_book .main_info_general .card-body{
    padding-bottom: 1rem;
}

.main_pda_viewer .card .divIcon, .main_dash .card .divIcon{
    display: flex;
    align-items: center;
    padding-top: 5px;
}

.main_pda_viewer .card .text-muted, .main_dash .card .text-muted{
    color:#7d7f7f !important;
}

.main_pda_viewer .card .card-header, .main_dash .card .card-header{
    min-height: 87px;;
}

.main_pda_viewer .top .card .card-body, .main_dash .top .card .card-body{
    min-height: 107.8px;
    margin-top: auto;
    margin-bottom: auto;
}

ul.fda-portal-items-details{
    padding-left: 0px;
    margin-top: 1rem;
}

ul.fda-portal-items-details li{
    list-style: none;
   
}

.main_right.admin-voyage{
    padding: 1rem;
}

.main_right.admin-voyage ul{
    list-style: none;
    padding: 0;
}
.main_right.admin-voyage li{
    margin-top: 1rem;
}


  .main_dash .progress
  {
    height: 70px;
    width: 70px;
    line-height: 100px;
    background: none;
    box-shadow: none;
    position: relative;
  }


.cargoReport-portal .progress-Ship  {
    width: 100%;
    height: 140px !important;
  }

.cargoReport-portal .progress-bl-reportCargo  {
    width: 100%;
    height: initial !important;
  }

  .main_pda_viewer .progress:after, .main_dash .progress:after,.main_book .progress:after {
    content: "";
    width: 100%;
    height: 100%;
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 0;
  }
  .main_pda_viewer .progress>span,  .main_dash .progress>span,  .main_book .progress>span{
    width: 50%;
    height: 100%;
    overflow: hidden;
    position: absolute;
    top: 0;
    z-index: 1;
  }
  .main_pda_viewer .progress .progress-left,  .main_dash .progress .progress-left,  .main_book .progress .progress-left{
    left: 0;
  }


 .main_pda_viewer .progress .progress-bar-dash, .main_dash .progress .progress-bar-dash, .main_book .progress .progress-bar-dash {
    width: 100%;
    height: 100%;
    background: none;
    border-width: 3px;
    border-style: solid;
    position: absolute;
    top: 0;
  }

  .main_pda_viewer .progress .progress-left .progress-bar, .main_dash .progress .progress-left .progress-bar {
    left: 100%;
    border-top-right-radius: 80px;
    border-bottom-right-radius: 80px;
    border-left: 0;
    -webkit-transform-origin: center left;
    transform-origin: center left;
  }

  .main_pda_viewer .progress .progress-right,  .main_dash .progress .progress-right{
    right: 0;
  }

  .main_pda_viewer .progress .progress-right .progress-bar, .main_dash .progress .progress-right .progress-bar {
    left: -100%;
    border-top-left-radius: 80px;
    border-bottom-left-radius: 80px;
    border-right: 0;
    -webkit-transform-origin: center right;
    transform-origin: center right;

  }

  .main_pda_viewer .progress .progress-right .progress-bar:not(.noAuto), .main_dash .progress .progress-right .progress-bar:not(.noAuto) {
    animation: loading-1 2s linear forwards;
  }

  .main_pda_viewer .progress .progress-value,  .main_dash .progress .progress-value{
    width: 90%;
    height: 90%;
    border-radius: 50%;
    background: var(--cPrincipalClaro);
    font-size: 14px;
    font-weight: 500;
    color: var(--cPrincipal);
    line-height: 135px;
    text-align: center;
    position: absolute;
    top: 5%;
    left: 5%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .main_pda_viewer .progress.blue .progress-bar, .main_dash .progress.blue .progress-bar {
    border-color: var(--cPrincipal);
  }

  .main_pda_viewer .progress.warning .progress-bar,  .main_dash .progress.warning .progress-bar{
    border-color: var(--bs-warning-text-emphasis)!important;
  }

  .main_pda_viewer .progress.warning .progress-value, .main_dash .progress.warning .progress-value {
    --bs-text-opacity: .1;
    background-color:rgba(var(--bs-warning-text-emphasis-rgb), var(--bs-text-opacity))!important;
    color:var(--cWarning) !important;
  }

  .main_pda_viewer .progress.success .progress-bar,  .main_dash .progress.success .progress-bar{
    border-color: var(--bs-success-text-emphasis)!important;
  }

  .main_pda_viewer .progress.success .progress-value,  .main_dash .progress.success .progress-value  {

    --bs-text-opacity: .1;
    background-color:rgba(var(--bs-success-text-emphasis-rgb), var(--bs-text-opacity))!important;
    color:var(--cSuccess)!important;
  }

  .main_pda_viewer .progress.danger .progress-bar, .main_dash .progress.danger .progress-bar {
    border-color: var(--bs-danger-text-emphasis)!important;
    
  }

  .main_pda_viewer .progress.danger .progress-value,  .main_dash .progress.danger .progress-value{
    --bs-text-opacity: .1;
    background-color:rgba(var(--bs-danger-text-emphasis-rgb), var(--bs-text-opacity))!important;
    color:var(--cDanger)!important;
    
  }


  .main_pda_viewer .progress .progress-left .progress-bar:not(.noAuto), .main_dash .progress .progress-left .progress-bar:not(.noAuto) {
    animation: loading-2 2s linear forwards 2s;
  }



  @keyframes loading-1 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: var(--chart1RightPda);
      transform: var(--chart1RightPda);
    }
  }

  @keyframes loading-2 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
      }
    
    100% {
        -webkit-transform: var(--chart1LeftPda);
        transform: var(--chart1LeftPda);
    }
  } 

  
  .ddlCurrency{
    min-width: unset !important;
    background: transparent;
    border: none;
    padding: 0;
    color: var(--cFontTitulo);
    display: inline !important;
    vertical-align: unset;
  }

  .ddlCurrency:active,.ddlCurrency:focus,.btn-secondary:hover{
    background-color: transparent;
    color:var(--cFontTitulo);
  }
  
.mt-6{
    margin-top:4rem;
}

.min-h-100{
    min-height: 100%;
}

.noIcon.noPadding .p-component, .noIcon.noPadding .p-inputtext{
    padding: 0px !important;
    padding-right: 10px !important;
}

p-dropdown.strong .p-inputtext{
   font-weight: 500 !important;
   color: #212529 !important;
}

.rowSelect{
    background-color: #f3f3f3;
}

.x-input.fondo{
    background-color: #F1F5F9;
}

.divReportProgress{
    font-size:14px !important;
    background-color: #fff;
    width:21.59cm;
    padding-bottom: 40px;
    position: absolute;
    /* visibility:hidden;  */
    display:block;
    z-index:-1;
    top: -1000px;
    left: -1000px;
}

.mat-horizontal-content-container,.mat-horizontal-content-container .mat-horizontal-stepper-content[aria-expanded=true]{
    display: flex;
    flex-direction: column;
    flex:1
}

.borderR{
    border-right: 1px solid var(--cPrincipal);
}

.partShipReport.separador{
    width: 11px;
}

.partShipReport{
height: 150px;
}

.progress.partShipReport{
    align-items: end;
    height: 140px;
    border-radius: 0;
    width: initial;
}

.partShipReport.bottom{
    height: 10px;
    background-color: #3e5e81;
}

.positionUnset{
    position:relative;
}

.imgTopReportProgress{
    width: 100%;
}
.logoReportProgress{
    width: 200px;
    margin-top:-25px;
}

.headerReportProgress{
    margin-top:30px;
    font-weight: bold;
}

.tituloReportProgress{
    padding-bottom: 5px;
    padding-right: 10px;
    border-bottom:1px solid var(--cPrincipal);
}

.infoSummaryreportProgress{
    border-left:1px solid var(--cPrincipal);
    padding-left: 5px;
    padding-bottom:5px;
    margin-top:5px;
    /* font-size:15px !important; */
}

.infoSummaryreportProgress label{
    display:flex;
}
.infoSummaryreportProgress label .dato{
    flex:1
}

.infoSummaryreportProgress label .title{
   width: 130px;
   font-weight: 500;
   display: inline-block;
}



.ms-15px{
    margin-left: 15px;
}

.progress.report{
    background-color: #beecfc;
}

.table.reportProgress{
    color:#000 !important
}

.table.reportProgress th{
    border-top:none !important
}

.divCharts label{
    display:block
}

.chartReportProgress{
    width: 140px;
    height: 140px;
    margin-bottom:10px;
    display: flex;
    align-items: center;
}

.identiLeyendaReportProgress{
    width: 10px;
    height: 10px;
    display: inline-block;
    border-radius: 50%;
    margin-right: 7px;
}
.identiLeyendaReportProgress.Top{
    background-color: var(--cPrincipal);
}
.identiLeyendaReportProgress.Bottom{
    background-color: #beecfc;
}

/*Board*/

    .main-board .rounded-pill{
        padding-inline: 10px;
        padding-block: 7px;
        align-self: center;
    }

    .main-board .rounded-pill i.fal{
        font-size: .6rem;
    }

    .bgBase{
        background-color: var(--cBase);
    }
    .main-board .bgBase{
        background-color: #f5faff !important;
    }
    

    .rounded-pill.pill-act-dischargind{
        background-color: var(--cDanger);
        font-size: .8rem;
    }
    .rounded-pill.pill-act-loading{
        background-color: var(--cSuccess);
        font-size: .8rem;
    }

    .text-actual{
        color: #1fb1dd;
    }

    .text-estimated{
        color: var(--cFont);
    }

    .text-bg-a{
        background-color: #1fb1dd;
    }

    .text-bg-e{
        background-color: var(--cFont);
    }

    .main-board .titulo_inside {
        font-size: 1.7rem;
    }

    .main-board{
    font-size: 1rem ;
    }

    .main-board{
        width: 100vw;
        height: var(--viewport-height);
        background-image: url(assets/img/cPrincipal.svg);
        background-repeat: repeat-x;
        background-size: 25% auto;
        font-size: 1.2rem;
    }


    .main-board  h1{
        font-size: 2rem;
    }
    .main-board  h4{
        font-size: 1.5rem;
        font-weight: 300;
    }

    .main-board  h1, .main-board h4{
        color:#fff
    }

    .main-board .header{
        
        background-color: #fff;
        height: var(--hHeader);
        position: sticky;
        top: 0;
        display: flex;
        padding: 18px var(--pGeneral);
        z-index: 4;
        box-shadow:  0 2px 5px 0 rgba(0, 41, 69, 0.10);
    }

    .main-board .container{
        height: 100%;
        
        
    }

    .main-board .container .row-top{
        height: 40%;
        background-color: var(--cPrincipal);
    }

    .main-board .container .row-top{
        height: 60%;
        background-color: var(--cBase);
    }


/**/

body{
    background: var(--cBase);
    width: 100%;
    height: 100%;
    overflow: hidden;
    font: 400 14px/1.25 -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Helvetica, Arial, sans-serif;

}

html body.swal2-height-auto{
    height: 100% !important;
}

h5{
    margin-bottom: 0px;
}

.table-responsive .dropdown,
.table-responsive .btn-group,
.table-responsive .btn-group-vertical {
    position: static;
}


.p-relative{
    position: relative;
}

form{
    position: relative;
}

p-dropdown{
    display: block;
    border-radius: 0.25rem;
}



.main_default{
    height: 100%;
    overflow: hidden;
}


.x-badge{
    height: 30px;
    border-radius: 6px;
    padding: 5px 20px ;
    letter-spacing: -0.25px;
    border:1.5px solid;
    font-weight: bold;
}

.x-vs-container-inputs{
    /* flex-grow: 1; */
    padding-right: 1.5rem;
}




.x-vs-container-inputs .p-dropdown.p-component::before{
    content: none;
}

.status-badge{
    height: 30px;
    border-radius: 6px;
    padding: 5px 20px ;
    letter-spacing: -0.25px;
    border:1.5px solid;
    font-weight: bold;
}

.badge-employed-depart{
    
    border-color:var(--cSecundario);
    color:var(--cSecundario);

}

.badge-employed-disabled{
    border-color:var(--cMuted);
    color:var(--cMuted);
}

.fa-bell.success{
    color:#ffc107
}

.fa-bell.no-success{
    color:#546e7a
}

.content-main>*{
    width: 100%;
}

.list-SplitPDA li{
    font-size: 1rem;
    padding-block: 0.5rem;
}


.status-badge.Sent{
    border-color: var(--cSuccess);
    color: var(--cSuccess);
}

.status-badge.Revised-Draft{
    border-color: #0078d7;
    color: #0078d7;
}

.status-badge.Draft{
    border-color: #0078d7;
    color: #0078d7;
}

.status-badge.Await{
    border-color: #ffc107;
    color: #ffc107;
}

.status-badge.Review{
    border-color: var(--cCuartario);
    color: var(--cCuartario);
}

.cWarning{
    color:var(--cWarning)
}

.alert-link{
    color:var(--cFontTitulo)
}

.alert-link:hover{
    color:var(--cFontTitulo);
    text-decoration: underline;
}

.form-group{
    display: flex;
}

.cFont{
    color:var(--cFont)
}

.item-timeline .titulo{
    text-align: center;
    margin-bottom: 1rem;
    font-size: .9rem;
    font-weight: 500;
}

.item-timeline .info{
    border-top: 2px solid var(--cSeparadores);
    text-align: center;
    position: relative;
    padding-top: 1rem;
}

.item-timeline .info::before{
    content: "";
    width: 15px;
    height: 15px;
    background-color: var(--cSecundario);
    border-radius: 25px;
    border: 4px solid var(--cSecundario);
    position: absolute;
    top: -8px;
    left: auto;
    transition: all 200ms ease-in;
}


.item-timeline .info.nodata::before{
    background-color: var(--cSeparadores);
    border: 4px solid var(--cSeparadores);

}

.resumen-portcall .info{
    display: flex;
}

.resumen-portcall .info .name{
    width: 200px;
    flex-shrink: 0;
    
}
.resumen-portcall span{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

hr.principal{
    color: var(--cPrincipal);
}

.cDanger{
    color:var(--cDanger) !important
}


.card_voyages.cargo-report .card-body div{
   max-width: 100%;
}

.item-errorPda{
    list-style: none;
}

.table.pda .col-item{
    min-width: 400px;
}


.table.pda .col-valor.noColor{
    min-width: 200px;
    background-color: transparent
}

.table.pda .col-valor{
    min-width: 200px;
    background-color: var(--cPrincipalClaro);
}

.table.payment .col-valor{
    background-color: var(--cPrincipalClaro);
}

.table.pda .col-opt{
    min-width: 150px;
    text-align: center;
}

.table.pda .rowTotal{
    background-color: var(--cRowDif);
    border-bottom: 1px solid rgb(222,226,230);
}

.main-filter{
    display: flex;
    justify-content: space-between;
}

.text-right{
    text-align: right !important;
}

input.text-right{
    text-align: right !important;
}

.btn-ops-ellipsis{
    border-radius: 50%;
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.btn-ops-ellipsis:hover{
    background-color: var(--cBase);
}

.mat-dialog-actions{
    padding-bottom: 16px !important;
}

.x-label{
    width: 140px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: flex;
    align-items: flex-start;
    flex-shrink: 0;
    /* padding-top: 10px; */
    /* color:var(--inputColor) */
    /* font-weight: 450; */
    color:var(--cFontOscuro)
}

.x-label.x-sm{
    width: 80px;
}

.x-label .disabled-icon{
    margin-inline: 10px;
    color:var(--cMuted)
}

.x-label label{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.x-label.start, .x-input.start{
    align-items: start;
}

.start{
    align-items: start !important;
}

.flex-1{
    flex:1;
    min-width: 0;

}

.x-scroll{
    height: calc(var(--viewport-height) - var(--hHeader));
    overflow: auto;
}

.bgPrincipal{
    background-color:var(--cPrincipal)
}
.cPrincipal{
    color:var(--cPrincipal)
}
.cSecundario{
    color:var(--cSecundario)  !important
}
.x-input{
    display:flex;
    align-items: center;

}




.x-input textarea.form-control ,.p-component ,.p-component.p-disabled, .p-disabled, .p-disabled *,.x-input input,.x-input input:disabled,.x-input input:hover,.x-input input:disabled:hover{
   border-color: transparent !important;
   background-color: transparent !important;
}

.x-input.search{
    max-width: unset;
}

.table .x-input{
    max-width: 300px;
}

.x-input{
    padding: 2px 5px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, background-color 0.15s ease-in-out;
    will-change: border-color, box-shadow, background-color;
    border-radius: 6px;
    min-height: 32px;
    border:solid 1px transparent;
    width: 100%;
    min-width: 0;
}

.x-input p-dropdown,.x-input p-multiselect{
    width: 100%;
}

.x-input input,.x-input .p-dropdown-label,.x-input .p-multiselect-label{
    font:var(--font);
    color:var(--inputColor);
    box-shadow: none;
    outline: none;
}

.p-inputtext:enabled:focus,.x-input input{
    box-shadow: none;
}

.p-disabled, .p-component:disabled{
    opacity: 1;
}

.x-input .p-component.p-dropdown,.x-input .p-component.p-multiselect {
    padding:0px !important;
    /* height: 35.5px; */
}

.p-inputtext{
    padding:0px;
}

.x-label:hover {
    color:var(--cInputLabelHover)
}

.x-input:hover, .x-label:hover ~ .flex-1 .x-input, .x-label:hover ~ .flex-1.x-input{
    border:solid 1px;
    border-color: rgba(176, 190, 197, 0.8) !important;
 }

/* Alerts*/

.alert{
    border:1px solid;
    border-left: 4px solid;
}

.alert-primario{
    border-color: var(--cSecundario);
}

.alert-advertencia{
    border-color: var(--cWarning);
}

.alert-secundario{
    border-color: var(--cSecundario);
}



/*Header*/

.x-titulo-seccion{
    margin-bottom: 1.5rem;
}

.main_header .page_icono, .titulo-modal .page_icono, .x-titulo-seccion .page_icono,.titulo-seccion-board .page_icono{
    min-width: 40px;
    min-height: 40px;
    box-shadow: 0 4px 12px 0 rgba(0, 41, 69, 0.24);
    border-radius: 6px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-right: 16px;
    color: #fff;
    font-size: 18px;
    align-self: flex-start;
}

.titulo-modal  .titulo, .x-titulo-seccion .titulo{
    margin-bottom: 0px;
    align-self: center;
}


.titulo-modal .page_icono.delete{
    background-color: var(--cDanger)
}


.main_header .page_icono.daportdata,.titulo-modal .page_icono.daportdata,.titulo-seccion-board .page_icono {
    background-color: var(--cSelectMainMenu)
}

.titulo-seccion-board{
    color:#fff
}




.x-titulo-seccion .page_icono.daportdata, .y-badge.daportdata{
    color: var(--cSecundario);
    background-color: #dae5eb;
}

.y-badge.fda{
    color: var(--cSuccess);
    background-color: var(--cSecundarioClaro2);
}
.y-badge.sda{
    color: var(--cPrincipal);
    background-color: var(--cPrincipalClaro);
}

.x-border-inline{
    border-inline: 1px solid var(--cSeparadores);
}

.main_header .page_icono.setting,.titulo-modal .page_icono.setting{
    background-color: var(--cPrincipal)
}

.x-titulo-seccion .page_icono.setting, .y-badge.setting{
    color: var(--cPrincipal);
    background-color: var(--cPrincipalClaro);
}



/*Fin*/

/* Login */

.btn-back, .x-btn-back{
    align-self: center;
}

.btn-back a,.x-btn-back a{
    border-radius: 50px;
    color: #546e7a;
    display: flex;
    align-items: center;
    padding: 10px;
    width: 34px;
    height: 34px;
   background-color:  #fafafae8;
   /* box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.05), 0 6px 12px 0 rgba(0, 0, 0, 0.05); */
   margin-right: 10px;
}
.login-back a{
    border-radius: 50px;
    position: absolute;
    top: 0;
    color: #546e7a;
    display: flex;
    align-items: center;
    padding: 10px;
    left: 1rem;
}

.login-back a:hover, .btn-back a:hover,.x-btn-back a:hover{
   background-color: #d9e2e6;
}

.main_login .left{
    /* position: relative;
    min-height: 60%;
    padding: 0 5rem;
    align-self: center; */
    position: relative;
    height: 300px;
    padding: 0 5rem;
    align-self: center;
}

.main_login .separador{
    width: 1px;
    height: 300px;
    position: absolute;
    background-color: var(--cPrincipal);
    right: 0;
}

.main_login,
.main_loginPDA {
    text-align: center;
    height: 100%;
    background: #fff;
}

.logo-lg{
    height: auto;
    width: 22rem;
}

.logo-sm{
    height: auto;
    width: 12rem;
}

.logo-xs{
    height: auto;
    width: 5rem;
}


.login_section{
    background-color: #fff;
    border-radius: 12px;
    box-shadow: 0px 10px 30px 0px rgba(90, 122, 190, 0.2);
    padding: 64px 24px;
    min-width: 400px;
}

.logo-login{
    /* position: absolute;
    top:0px; */
    margin-bottom: 50px;
}

/* Fin */

/* Formularios */

.form-floating{
    width: 100%;
}

.form-floating > textarea{
    padding-top: 1rem !important;
}

.form-floating  .p-dropdown{
    border: 1px solid rgb(206, 212, 218) !important;
    border-radius: 4px;
    color:#212529;
}
.form-floating  .p-dropdown:hover{
    border-color: black !important;

}

.form-floating  .p-dropdown-label.p-placeholder,.form-floating .p-dropdown .p-dropdown-label{
    color:#212529 !important;
    padding-inline: 0;
}
.form-floating  .p-dropdown.p-component::before{
    content: none;

}

.form-control:hover{
    border-color:var(--cPrincipal) !important;
}
.form-control:focus{
    box-shadow:none;
}

.form-floating>.form-control, .form-floating>.form-select {
    height: calc(3rem + 2px);
    line-height: 1.25;
}

.form-floating>.form-control:focus~label, .form-floating>.form-control:not(:placeholder-shown)~label, .form-floating>.form-select~label {
    opacity: .65;
    transform: scale(.85) translateY(-0.8rem) translateX(0.15rem);
}

.form-floating>label {
    
    padding: 0.9rem 0.75rem 0.8rem 0.75rem;
    
}



.card-footer{
    background-color: var(--cBase);
}

.form-control.is-invalid, .form-group p-dropdown.is-invalid{
    /* border:1px solid var(--cDanger) !important */
}

.form-control:focus{
    box-shadow: none !important;
}

.form-control.is-invalid + label, .form-group p-dropdown.is-invalid .p-dropdown-label{
    color: var(--cDanger)
}

.form-control.is-invalid ~  .see-password{
    top: 1.2rem;
    right: 30px;
}

 .see-password{
    position: absolute; 
    top: 1.3rem; 
    right: 10px;
}

.inside-icon-input{
    position: absolute; 
    top: 5px; 
    right: 10px;
    border:none;
}

.inside-icon-input .icon{

    border:none !important;
}

.table-sumary{
    background-color: var(--cPrincipalClaro);
    border-radius: 10px;
}

.table-sumary table{
    margin-bottom: 0;
    border-inline: 1px;
}
.table-sumary table thead th{
    border-top: none !important;
}
.table-sumary table tbody tr:last-child td{
    border-bottom: none !important;
}

.x-btn{
    font:var(--font);
    color:var(--cPrincipal);
    font-weight: 500;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: baseline;
    border-radius: 5px;
}

.x-btn:hover{
    background-color: var(--cBase);

}

.x-see-password i{
    border: none !important;
    font-size: .8rem;
}
.x-see-password{
    position: absolute; 
    top: 2px; 
    right: 10px;
}
/* Fin */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
    -moz-appearance:textfield; /* Firefox */
}

.row-vesselStatus{
    margin-bottom: 1rem;
}

textarea {
    height: 100% !important;
}

a {
    text-decoration: none;
    color: unset;
}

a:hover {
    text-decoration: none;
    color: unset;
}

.border-left-none{
    border-left:none !important
}

.border-right-none{
    border-right:none !important
}

p {
    margin-top: 0;
    margin-bottom: 0.5rem;
}

tr.valorEdit {
    background-color: var(--cPrincipalClaro2);
}

.opt-pda-items{
    text-align: center;
}

.opt-pda-items a{
    margin-right: .5rem;
}

.main-file-upload{
    border:solid 1px var(--cSeparadores);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.main-upload-invoice .bottom .left{
    border: solid 1px var(--cSeparadores);
    padding: 0px;
}

.main-upload-invoice{
    min-height: 800px;
}

.main-upload-invoice .bottom .right .btn-sm{
   margin: 0px .3rem;
   min-width: 50px;
}

.main-upload-invoice .bottom .right .btn-sm .fal{
    padding: 0px;
 }

.main-upload-invoice .bottom .right{
    border-top: solid 1px var(--cSeparadores);
    border-right: solid 1px var(--cSeparadores);
    border-bottom: solid 1px var(--cSeparadores);

}

.main-upload-invoice .bottom .right> div{
    
    padding: 1rem 1rem 0 1rem;
}

.main-upload-invoice .bottom .left .header-list{
    padding: 1rem;
    height: 79px;
    border-bottom: 1px solid var(--cSeparadores);
}

.main-upload-invoice .header-list{
    /* background-color: var(--cPrincipalClaro2); */
    padding: .5rem;
}

.all-0{
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

}

.main-upload-invoice .list,.main-fda-portal .list{
    /* max-height: 500px; */
    overflow-y: auto;
}

.main-upload-invoice .file{
    /* height: 389px; */
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.main-upload-invoice .list .select{
    background-color: var(--cBase);
}


.main-upload-invoice .right{
    position: relative;
    display: flex;
    flex-direction: column;
}

.main-upload-invoice .right .opt{
    top:10px;
    right: 10px;
    position: absolute;
}
.main-upload-invoice .right .opt .z-btn{
    background-color: transparent;
    border:none;
    /* margin-inline: .5rem; */
}

.z-btn .aprovado{
    color:var(--cSuccess)
}

.main-upload-invoice .right .titulo_inside{
    color:unset;

}
.cSuccess{
    color:var(--cSuccess);
}

.cards-mount{
    border-bottom: 1px solid var(--cSeparadores);
    padding-bottom: 1rem !important;
}

.main-upload-invoice .right .cards-mount > div:not(:first-child){
    border-left: solid 2px var(--cSeparadores);
    padding: 0px 1rem;
}
.main-upload-invoice .right .list-mensajes{
    max-height: 400px;
    overflow-y: auto;
}

.main-view-invoice .top .card{
    min-width: 300px;
    background-color:  var(--cPrincipalClaro2);
}

.main-view-invoice .bottom .card{
    /* background-color:  var(--cPrincipalClaro2);
    margin-bottom: 0px; */
}

.main-view-invoice .bottom .cards .count{
    font-size: 1.4rem;
    font-weight: 500;
}

.main-view{
    /* margin-bottom: 5rem; */
}

.main-view-invoice .bottom .cards .name{
    color:var(--cFont);
    display: block;
    margin-bottom: 0px;

}

.main-view-invoice .bottom .table tr{
    vertical-align: middle;
    text-align: center;
}

.x-titulo-seccion {
    margin-right: 1rem;
}

.page_icono.x-border{
    box-shadow: none;
    color:var(--cfont);
    border-radius: 50%;
    border:1px dotted var(--cFont);
    

}

.main-view-invoice .bottom .table tr th{
    /* border: solid;
    border-width: 0.5px; 
    border-color: var(--cGrisClaro); */
}

.text-muted{
    color: var(--cMuted) !important;
}

.break-spaces{
  white-space:   break-spaces;
}

.y-btn{
    color: var(--cPrincipal);
    background-color: var(--cPrincipalClaro);
    width: 34px;
    height: 34px;
    border-radius: 50%;
    border:none;

}

.y-btn.cNeutro{
    color: var(--cPrincipal);
    background-color: transparent;
}

.hold-body{
    height: 200px;
    overflow-y: auto;
    overflow-x: hidden;
}

.main_dischargered, .list-reports {
    padding-inline:15px;
    padding-block: 1rem;
}

.dischargers .main_dischargered,.list-reports  {
    border-top: 1px solid var(--cSeparadores)
}

.item_cargoStorage {
    margin-top: 1rem;
    /* border-left: solid 2px transparent; */
    padding-inline: .5rem;
    border-bottom: 1px solid var(--cSeparadores);
    padding-bottom: 1rem;
}

.card-vesselStatus-formulario{
    padding-inline: .5rem;
    padding-block: 1rem;
    
}

.card-vesselStatus-formulario .x-label{
    width: 100px;
}


.item.select {
    border-left:2px solid var(--cSecundario);
}

.info_dischareged .cantidad {
    font-size: 1.3rem;
    font-weight: 500;
}

.totales_cargo .titulo {
    font-size: 1rem;
    display: block;
    margin: 0px;
    font-weight: normal;
    color:var(--cFont)
}

.main_vesselStatus{
    /* height: 100%; */
    /* display: flex;

    flex-direction: column;*/
} 
.main_vesselStatus .main_opciones{
   position: sticky;
   top:-1.5rem;
   z-index: 9999;
   background-color: #fff;
} 

.main_vesselStatus >.card{
    /* overflow: auto;
    height: 100%; */
}

.x-btn-back{
    position: absolute;
    top:-40px;
    right: 10px;
}

.main_vesselStatus

.list-empty{
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: var(--cSecundarioClaro);
    display: flex;
    flex-direction: column;
    justify-content: center;
    color:var(--cSecundario);
}

.empty{
    width: 100px;
    height: 100px;
    border-radius: 50%;
    /* background-color: var(--cSecundarioClaro2); */
    display: flex;
    flex-direction: column;
    justify-content: center;
    color:var(--cSecundario);
}



.empty .icon{
    font-size: 4rem;
}

.totales_cargo .cantidad {
    font-size: 1.5rem;
    display: block;
    font-weight: 500;
}

.mat-step-header .mat-step-icon-selected, .mat-step-header .mat-step-icon-state-done, .mat-step-header .mat-step-icon-state-edit{
    background-color: var(--cSecundario);
}

.mat-step-icon-content{
    visibility: hidden;
}

.info_hold{
    border-bottom: 1px solid var(--cSeparadores);
    padding-bottom: 1rem;
} 

.info_hold .titulo {
    font-size: 1rem;
    margin: 0px;
    margin-right: .5rem;
    font-weight: normal;
    color:var(--cFont);
}

.info_hold .numero {
    font-size: 1.5rem;
    font-weight: 500;
}

.form-group {
    margin-bottom: 1rem;
    align-items: center;
}

.btnOptStatusVessel {
    padding: 5px 15px !important;
    /* padding: 2px 10px !important; */
    min-width: auto !important;
    border-color: var(--cPrincipal);
}


/* .table>:not(:last-child)>:last-child>* {
    border-bottom-color:;
} */

.onlyAMPM .p-hour-picker,
.onlyAMPM .p-separator,
.onlyAMPM .p-minute-picker {
    display: none !important;
}

.main_admin_voyage .main_info_general .card {
    margin-bottom: unset;
}

.lnkAddComent {
    text-decoration: underline !important;
    color: var(--cGrisClaro) !important;
    font-size: .7rem;
    margin-bottom: .5rem;
}

.btn-group {
    margin-bottom: 0.5rem;
}


/* .form-check-input {
    border: 1px solid var(--cGrisClaro) !important;
} */

.p-timepicker {
    background-color: #fff !important;
}

.p-datepicker .p-timepicker span {
    font-size: var(--fzGeneral) !important;
}

.p-timepicker>div {
    display: inline !important;
    text-align: center;
}

.mat-datepicker-input:focus-visible,
.mat-datepicker-input:focus {
    box-shadow: none !important;
    border-color: none !important;
    outline: 0 none;
    outline-offset: 0;
}

.p-calendar.p-calendar-timeonly {
    padding: 0px !important;
}

.main_admin_voyage .titulo_card .select {
    border-bottom: 1px solid var(--cFont);
    padding-bottom: 3px;
}



.main_toAcceptAppointment .card {
    margin-bottom: 2rem;
}

.main_info_general {
    font-size: .8rem;
}

.main_info_general .col-lg-3 {
    font-size: .7rem;
}

.main_admin_voyage .main_right {
    background-color: var(--cBase) !important;
    height: 100%;
}

.main_info_general .card-body,
.main_info_general .card-header {
    padding: 10px;
}

.main_admin_voyage .titulo_card {
    font-weight: 500;
    font-size: .9rem;
}

.main_admin_voyage .card .info-name {
    font-weight: 500;
    margin-right: 20px;
    display: block;
}

.main_admin_voyage .card .info-text {
    display: block;
    margin-right: 1rem;
}

.main_admin_voyage .card .text-truncate .info-text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}


.dUnset{
    display:unset !important;
}

.bgSecundarioClaro2 {
    background-color: var(--cSecundarioClaro2);
}

.bcBse {
    background-color: var(--cBase);
}

.bgSecundarioClaro{
    background-color: var(--cSecundarioClaro) !important;
}

.main_card_voyages {
    display: flex;
    margin-bottom: 2rem;
}

.row-top-board {
    background-image: url(assets/img/cPrincipal.svg);
    background-repeat: repeat-x;
}

.main_card_voyages > .card-header, .main_admin_voyage .card-header{
    background-color: var(--cBase) !important;
}


.card_voyages .main_list_note ul{
    padding-left: 0px;
    margin-bottom: 0px;
    list-style: disc;
    list-style-position: inside;
}

.card_voyages .info-voyage ul{
    list-style: none;
    padding-inline: 0px;
    margin-bottom: 0px;
}

.card_voyages .info-voyage ul li{
    margin-bottom: 1rem;
}

.card_voyages.destacado{
    border-color: #ffc107;
}



.card_voyages_left {
    padding: 0 1rem;
}

.main_voyages_vessel div {
    margin-bottom: 1rem;
    font-weight: 500;
    color: var(--cFontTitulo)
}

.card_voyages_right div.list_eta {
    margin-bottom: 0.5rem;
}

.main_voyages_vessel div span,
.card_voyages_right div span {
    display: block;
}

.card_voyages_vessel {
    font-size: 1.1rem;
    color: var(--cPrincipal)
}

.list_eta {
    border-left: 1px solid var(--cGrisClaro);
    padding-left: .55rem;
}

.btn_addInfo {
    border-top: 1px solid var(--cGrisClaro);
    padding-top: 1rem;
}



.add_note {
    display: flex;
    justify-content: flex-end;
}

.add_note .btn_note {
    padding: .1rem .7rem;
    background-color: var(--cPrincipalClaro2);
}

.mat-date-range-input {
    display: inline !important;
    /* width: initial !important; */
}

.mat-date-range-input-start-wrapper {
    max-width: calc(40% - 4px) !important;
}

.btnClearPicker {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    position: absolute;
    top: 2px;
    right: 0;
}

.btnClearPicker i {
    padding-right: 0px !important;
}

.add_note a.btn_note {
    color: var(--cFont);
}

.main_card_voyages .main_finantial {
    background-color: var(--cPrincipalClaro2);
}

.btnOptStatusVessel.select {
    background-color: var(--cPrincipal);
    border-color: var(--cPrincipal);
    color: #fff !important;
}

.main_list_note {
    padding: .5rem .7rem;
    overflow-y: auto;
}



.titulo_nomination {
    color: var(--cSecundario);
    font-weight: 500;
}


.dropdown-menu {
    padding: 0 !important
}

.aNormal {
    color: var(--cFont);
}

.aNormal:hover,
.aNormal:active,
.aNormal:visited {
    color: var(--cFont);
}

.strong {
    font-weight: 500 !important;
}

.zindex2 {
    z-index: 2;
}

.main_logOp table.tableLogOp thead th:first-child {
    width: 200px;
}

.p-datepicker table th {
    text-align: center;
}

.disabled {
    cursor: not-allowed;
    opacity: 0.5;
    color: currentColor;
    display: inline-block;
    /* For IE11/ MS Edge bug */
    pointer-events: none;
    text-decoration: none;
}

.cell-valor {
    text-align: right !important;
}

.swal2-styled.swal2-confirm {
    background: var(--cPrincipal) !important;
    text-transform: capitalize;
}

.checkAppo {
    color: var(--cDanger);
    font-weight: 400;
}

/* .form-control:disabled,
.form-control[readonly] {
    background-color: #e9ecef !important;
    opacity: 1;
} */


.icon-edit-perfil{
    position: absolute;
    top:20px;
    right:20px;
}

.x-card-profile .x-input:hover{
    border-color:transparent !important;
}

.x-card-profile{
    background-color: #fff;
    /* padding-top: 2rem; */
    padding-inline: 0;
    max-height: 600px;
}
.x-card-profile .list-data{
    padding-left: 0;
}

.icon{
    flex-shrink: 0;
}

.x-card-profile li{
    
    margin-bottom: 1rem;
    
}

.x-card-profile .titulo{
    font-size: 2rem;
}

.x-card-profile a{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.main_profile .x-card-profile .top {
    color: var(--cPrincipal);
    padding: 1.5rem 1rem;
    position: relative;
}

.main_profile .x-card-profile .bottom {
    color: #fff;
    padding: 3rem;
    position: relative;
    flex-grow: 1;
    background: rgb(7,36,70);
}

.olas{
    /* margin-top:2rem; */
    background-image: url(./assets/img/ondas2.png);
    width: 300px;
    height: 32px;
    background-repeat: no-repeat;
    background-size: 100%;
    flex-shrink: 0;
}

/* Appointment */

.main_pending_apppointment .list-md-inline-item {
    margin-right: 1rem;
}

.main_pending_apppointment .card_header_appointments .titulo_card {
    font-size: 1.2rem;
}

.main_pending_apppointment .card_header_appointments {
    border-bottom: 1px solid var(--cSeparadores);
    padding-bottom: 1rem;
}

.main_pending_apppointment ul {
    list-style: none;
    padding: 0px;
    margin: 0px;
}

.main_pending_apppointment .tituloItem {
    font-weight: 500;
    font-size: var(--fzTitulosInside);
    color: var(--cFontTitulo)
}


.card_Appointments .card-header {
   background-color: var(--cBase);
}

.card_Appointments .borderRight {
    border-bottom: 2px solid;
}

.card_Appointments .borderRight.rechazada {
    border-color: var(--cDanger);
    /* background-color: var(--cDanger) */
}

.card_Appointments .borderRight.sinPDA {
    border-color: #ffc107;
    /* background-color: #ffc107 */
}

.card_Appointments .borderRight.sinAceptar {
    border-color: #0d6efd;
    /* background-color: #0d6efd; */
}

.card_Appointments .borderRight.aceptada {
    border-color: #198754;
    /* background-color: #198754; */
}


/*Fin*/


/* NG-prime */

.custom-control-input:checked~.custom-control-label:before {
    border-color: var(--cPrincipal);
    background-color: var(--cPrincipal);
}

.p-checkbox .p-checkbox-box.p-highlight {
    border-color: var(--cPrincipal);
    background: var(--cPrincipal);
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
    background: var(--cPrincipal);
}

.p-chips .p-chips-multiple-container .p-chips-token {
    background: var(--cPrincipal);
}

.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
    background: var(--cPrincipal);
    border-color: var(--cPrincipal);
}

.p-paginator .p-paginator-first,
.p-paginator .p-paginator-prev,
.p-paginator .p-paginator-next,
.p-paginator .p-paginator-last {
    color: var(--cPrincipal);
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
    background: var(--cPrincipal);
}

.p-paginator-bottom .p-dropdown-panel .p-dropdown-items .p-dropdown-item {
    padding: 0.5rem 0.5rem;
}


/* fin */

.divCargando {
    min-height: 60vh;
}

.card_history_comentsPDA {
    flex-direction: row;
    display: flex;
    padding: 1rem;
    border-bottom: 1px solid var(--cSeparadores);
    margin-bottom: 1rem;
}

.card_history_comentsPDA .name {
    display: block;
    font-weight: 500;
    font-size: var(--fzTitulosInside);
}

.card_history_comentsPDA .badge {
    color: #fff;
}

.card_history_comentsPDA .last_coment {
    display: block;
    color: var(--cMuted)
}

.infoAccountDialog div {
    font-size: var(--fzGeneral);
    font-weight: normal;
    line-height: normal;
    display: flex;
}

.infoAccountDialog span.name {
    font-weight: 500;
    flex-shrink: 0;
    padding-right: 5px;
}

.main_history_pda {
    display: grid;
}

.main_history_pda .ultColum,  .table-invoices .ultColum{
    min-width: 60px !important;
}

.min-w-200px {
    min-width: 200px !important;
}
.min-w-400px {
    min-width: 400px !important;
}


/* Alerts */

.iconNoti .badge {
    font-size: 40%;
    color: #fff;
    position: absolute;
    top: 5px;
    left: 10px;
}

.header_notificaciones a {
    font-size: 0.7rem !important;
}

.alert-notificaciones {
    width: 350px;
    max-height: 500px;
    overflow-y: auto;
    background-color: var(--cBase);
}

.alert-notificaciones .notificacion {
    background-color: #fff;
    /* border: 1px solid #fff; */
    margin: 5px 0px;
    padding: 1rem 0;
    display: flex;
}

.alert-notificaciones .notificacion .info {
    padding: 0 1rem;
    cursor: pointer;
    border-radius: 5px;
    display: flex;
    font-size: var(--fzGeneral);
    flex: 1;
    width: 100px;
}

.alert-notificaciones .notificacion a {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}

.alert-notificaciones .notificacion .icon {
    padding-right: 1.3rem;
    font-size: 1.8rem;
}

.alert-notificaciones .notificacion:hover {
    background-color: #f8f9fa;
}

.alert-notificaciones .notificacion:hover .icon {
    /* border-color: var(--cSecundario); */
    color: var(--cSecundario)
}

s .alert-notificaciones .notificacion .icon {
    color: var(--cSeparador);
    font-size: 1.3rem;
}

.alert-notificaciones .notificacion .cerrar {
    font-size: 0.7rem;
}

.alert-notificaciones .notificacion .mensaje {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    width: 100%;
}

.alert-notificaciones .notificacion .mensaje .name {
    font-weight: 500;
    font-size: 1rem;
    /* flex-shrink: 0; */
}

.alert-notificaciones .notificacion .mensaje .fecha {
    font-size: 80%;
}


/* fin */



.main_pda_viewer .menuOpen {
    transition: 1.5s;
    display: block;
    position: fixed;
    height: 100%;
    margin-top: -18px;
    background-color: var(--cPrincipal);
}

.main_pda_viewer .menuOpen ul {
    list-style: none;
    padding: 0px;
}

.main_pda_viewer .menuOpen ul li {
    list-style: none;
    padding: 15px;
    font-size: 1rem;
    border-top: 1px solid var(--cPrincipalSelect);
}

.main_pda_viewer .menuOpen ul li a {
    color: #fff;
}

.main_pda_viewer .content-pda{
    padding: 3rem;
    
}

.main_pda_viewer  .main_info {
    height: calc(100vh - 125px - 4rem);
    overflow-y: auto;
    position: relative;
    margin-top: 2rem;
}

.listComents{
    background-color: var(--cBase);
    padding: 1rem;
}

.comentsItemsPDA .listComents,.main-upload-invoice .listComents {
    height: 300px !important;
    overflow-y: auto;
    flex-direction: row;
}

.comentsItemsPDA .listComents .coments{
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    width: auto;
    max-width: 80%;
    padding: 10px;
    word-break: break-all;
    margin: 0.3rem 0px;
}

.main-upload-invoice .listComents .coments .coment{
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    width: auto;
    word-break: break-all;
    color:#fff;
    padding: 10px;
    margin-bottom: 0.3rem;
}

.comentsItemsPDA .listComents .coments .fecha, .main-upload-invoice .listComents .coments .fecha {
    font-size: 60%;
}

.comentsItemsPDA .listComents .comentsMe  {
    background-color: var(--cPrincipal);
    color: #fff;
    margin-left: auto;
}

.main-upload-invoice .listComents .coments.comentsMe .coment{
    background-color: var(--cPrincipal);
}
.main-upload-invoice .listComents .coments.comentsNoMe .coment{
    background-color: var(--cSecundario);
}
.main-upload-invoice .listComents .comentsMe{
    margin-left: auto;
}

.main-upload-invoice .listComents .comentsNoMe {
    margin-right: auto;
}

.comentsItemsPDA .listComents .comentsNoMe, .main-upload-invoice .listComents .comentsNoMe  {
    background-color: var(--cPrincipalClaro);
    color: #fff;
    margin-right: auto;
}



.previewPDA {
    height: 100%;
    width: 100%
}

.wDialogLG,
.wDialogMD,
.wDialogSM {
    max-width: 95vw !important;
    overflow: auto;
}

.noHiper {
    cursor: inherit;
}

.noDecorate {
    text-decoration: none !important
}

.sumaryMensajePDA label {
    display: block;
}

.sumaryMensajePDA label.titulo {
    font-weight: 500;
}

.infoItemFormula span,
.infoItemFormula a {
    font-size: 0.7rem;
}

.infoItemFormula a {
    text-decoration: underline;
}

.previewPDF {
    height: 500px;
    width: 100%;
}
.previewBoard {
    height: 400px;
    width: 100%;
}

.imgReportBoard{
    background-color: #fff;
    max-width: 1400px;
    /* width: 100%; */
    position: absolute;
    top: 0;
    left: -10000px;
    z-index: -1;
}

.alert.coments {
    font-size: initial;
    letter-spacing: normal;
    line-height: normal;
    font-weight: 300 !important;
    font-size: var(--fzGeneral);
}

.alert-primary {
    background-color: var(--cPrincipalSelect);
    color: inherit
}

.alert-primary .fal {
    color: var(--cPrincipalClaro)
}

.alert-primary .btn-primary,
.alert-primary .btn-primary:active {
    background-color: var(--cPrincipal) !important;
}

.coments span {
    white-space: break-spaces;
    -ms-word-break: break-all;
    word-break: break-all;
    word-break: break-word;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto; 
    hyphens: auto;
}

.iconList {
    margin-right: .5rem;
    font-size: 1.2rem;
}

a {
    cursor: pointer;
}

.fw500 {
    font-weight: 500;
}

.flag-item-value,
.flag-item {
    display: flex;
    align-items: baseline;
}

.flag-item-value i,
.flag-item i {
    margin-right: var(--mIcono);
}

.mat-dialog-title {
    margin-bottom: 20px !important;
}

/* .p-component {
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    width: 100%;
    border: 1px solid #ced4da !important;
} */

.p-component.p-checkbox {
    width: inherit;
}

.p-inputtext.p-multiselect-filter,
.p-inputtext.p-dropdown-filter {
    background-color: #fff;
}

.p-inputtext::placeholder {
    color: var(--cGrisClaro);
    font-weight: 300;
}

.p-inputtext {
    font-weight: 300 !important;
}

.colorBody {
    color: var(--cFont);
}

.close:not(:disabled):not(.disabled):focus,
.close:not(:disabled):not(.disabled):hover {
    opacity: 0.75;
}

.input-group-text {
    border: none;
}

.alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.75rem 1.25rem;
    color: inherit;
}

[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled),
button:not(:disabled) {
    cursor: pointer;
}

.popoverInfo:focus,
button.close:focus {
    outline: none;
}

.alert{
    color:var(--cFont)
}

.alert-dismissible {
    padding-right: 1.25rem;
}

.alert .close {
    /* display: flex; */
    font-size: inherit;
    /* position: relative; */
    /* margin-left: auto; */
    padding: 0;
    top: 15px;
    right: 15px;
}

.alert .msgAlert {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.animate__animated {
    -webkit-animation-fill-mode: none;
    animation-fill-mode: none;
}

.color_secundario {
    color: var(--cSecundario) !important;
}

.btn {
    min-width: 100px;
}

.content_titulos {
    color: var(--cFontTitulo);
    font-size: var(--fzTitulos);
}

.content_subtitulos {
    color: var(--cGrisClaro);
    font-size: 0.8rem;
}

.text-principal{
    color:var(--cPrincipal)
}

label {
    cursor: inherit;
}

.rounded, .rounded .card-body,.main_info {
    border-radius: 8px !important;
}



.p-multiselect-label-container{
    position: relative;
    width: 100%;
    height: 30px;
}

.p-multiselect-label {
    position: absolute; 
    width: 100%;
}

.p-dropdown.p-component,
.p-multiselect.p-component {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}

.mat-date-range-input-container,
.mat-datepicker-input {
    align-items: unset !important;
}

.mat-date-range-input-start-wrapper {
    flex-grow: 1;
}

.mat-date-range-input-separator {
    align-self: center;
}

.main_card_voyages .card-header{
    background-color: transparent;
}

.form-control,
.p-component.p-dropdown,
.p-calendar,
.p-multiselect,
.p-dropdown-filter .p-inputtext,
.mat-date-range-input-inner,
.mat-datepicker-input {
    /* border: 1px solid #ced4da !important; */
    font:var(--font);
    background-color: var(--cFondoInput) !important;
    width: 100%;
    padding: 0.375rem 0.75rem !important;
    line-height: 1.5;
    background-clip: padding-box;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    box-shadow: none;
}


.mat-date-range-input-inner {
    border: 1px solid #ced4da !important;
}

.p-dropdown-label.p-placeholder,.p-multiselect-label.p-placeholder{
    font-size: 0.9rem;
    font-weight: 300;
    display: flex !important;
    align-items: center;
    background-color: var(--cFondoInput);
    width: 100%;
    border: none;
    color:#858d93 !important;
    flex:1;
    padding-left: 0.75rem;
}

.main_vesselStatus .text-ocultable{
  height: 150px;
  overflow: hidden;
  display: inline-block;
}


.tipoPortEvent .small::before{
    content:'';
    height: 10px;
    width: 10px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 10px;
}

.tipoPortEvent .small.estimate::before{
    background-color: var(--cSecundario);
}

.tipoPortEvent .small.actual::before{
    background-color: var(--cPrincipal);
}


.main_vesselStatus .text-ocultable::before{
  content: "";
  position: absolute;
  bottom: 0;
  left:0;
  width: 100%;
  height: 100px;
  background: linear-gradient(transparent,#fff);
}

.main_vesselStatus .text-ocultable.show{
    -webkit-line-clamp: none;
    max-height: 300px;
}

.trError td:not(:last-child){
 color:var(--cDanger);
}

p-calendar{
    padding-left: 0.75rem;
    height: 31.5px;
}

.p-multiselect,
.p-dropdown-trigger-icon,
.p-multiselect-trigger-icon,
.mat-date-range-input-container,
.mat-datepicker-input {
    font-size: 0.7rem;
    font-weight: 300;
    display: flex !important;
    align-items: center;
    background-color: var(--cFondoInput);
    width: 100%;
    border: none;
}

.p-dropdown-label{
    width: auto;
    margin-top: auto;
    margin-bottom: auto;
    flex: 1;

}

.table .p-dropdown-label{
    white-space: nowrap !important;
    -webkit-line-clamp: 1;
    max-width: 300px;
    text-overflow: ellipsis;
    overflow: hidden;
}

.curet-Report{
    font-size: 35px;
    color: #beecfc;
}

.p-dropdown-label,
.p-multiselect .p-multiselect-label {
    padding-inline: 0.75rem;
    padding-block: 6px;
}

.p-calendar {
    padding: 0px;
}



.p-chips-multiple-container {
    flex-wrap: wrap;
    height: auto;
}

.p-inputtext,
.p-dropdown-panel,
.p-multiselect-panel,
.p-chips .p-chips-multiple-container .p-chips-input-token input {
    font: var(--font) !important;
    font-size: 0.9rem;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item,
.p-multiselect-panel .p-multiselect-items .p-multiselect-item {
    color: var(--inputColor);
}



.p-dropdown-items-wrapper,
p-multiselect-items-wrapper {
    width: 100%;
}

.p-dropdown:not(.p-disabled).p-focus,
.p-multiselect:not(.p-disabled).p-focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.p-dropdown:not(.p-disabled).p-focus{
    outline: 0 none;
    outline-offset: 0;
     box-shadow: 0 0 0 0.2rem transparent; 
    border-color: transparent;
}

p-dropdown:focus-visible{
    outline: none;
}

.p-dropdown-panel,.p-multiselect-panel{
    background-color: #fff !important;
    border:1px solid rgba(0,0,0,.15) !important;
    font-weight: 400;
    padding: 0px;
    color: var(--cFondoInput);
    font-size: 0.9rem;

}

.noIcon .p-dropdown.p-component::before{
    
    display: none;

}



.p-dropdown .p-dropdown-trigger,
.p-multiselect .p-multiselect-trigger {
    width: initial;
}

.p-dropdown-panel {
    height: auto;
    /* max-width: min-content; */
}

.p-dropdown-panel .p-dropdown-header .p-dropdown-filter {
    padding-left: 0.75rem;
}

.p-dropdown-empty-message {
    padding: 0.5rem 1.5rem;
}

.p-component.p-disabled,
.p-disabled,
.p-disabled * {
    /* cursor: default !important;
    background-color: #e9ecef !important;
    opacity: 1;
    color: var(--cGrisClaro); */
}

.main_content.filter {
    filter: brightness(0.5);
    background-color: var(--cBase);
}

.tableROE th:Last-child {
    min-width: inherit;
    max-width: inherit;
    width: 100px;
    text-align: center;
}

.tableROE th {
    min-width: inherit;
    max-width: inherit;
    width: 200px;
}

.tableROE th:first-child {
    min-width: inherit;
    max-width: inherit;
    width: auto;
    text-align: left;
}

.p-paginator .p-paginator-current {
    display: none !important;
}


.p-datatable-wrapper {
    overflow: auto;
}

.p-datatable .p-datatable-tbody>tr {
    color: var(--cFont)
}

.main_history_pda .p-datatable-wrapper table {
    margin-bottom: 2rem;
}

a .btn {
    color:var(--cFontOscuro);
}

.main_history_pda .p-datatable .p-paginator-bottom {
    margin-top: -100px;
}

.p-datatable-wrapper table {
    table-layout: auto !important;
}

.table {
    color: var(--cFont);
    table-layout: auto;
}

.table i.fal {
    font-size: 1rem;
}

.listPolicy, .listBack {
    max-height: 300px;
    overflow-x: auto;
    padding-inline: 10px;
}

.main_default .table th:first-child {
    /* min-width: 200px;
    max-width: 200px; */
}

.main_default .table .tableSumary th:first-child {
    min-width: 150px;
    max-width: 150px;
}

.main_default .table th:last-child {
    min-width: 100px;
    text-align: center;
}

.table .rowTotalPDA {
    color: var(--cPrincipal);
    font-size: 1.1rem;
    font-weight: 500;
    text-align: right;
}

.table>:not(:last-child)>:last-child>*{
    border-top: 1px solid;
    border-color:inherit
}

.table>:not(caption)>*>* {
    padding: 1rem 0.5rem;
}

.table .ultColum{
    border-left: 1px solid;
    border-color: inherit;
}

.table .ultColum .dropdown{
   justify-content: end;
}

.table .ultColum .dropdown .fa-ellipsis-h,.table .ultColum .dropdown .fa-spin  {
   font-size: 1.7rem;
}

.fa-spin{
    padding: 0 !important;
}


.table thead tr th {
    font-weight: 500;
}

.main_default .table tr:not(:last-child),
.tableSumary,
.main_default .table thead tr,
.main_pda_viewer .table thead tr,
.main_pda_viewer .table tr:not(:last-child) {
    /* border-left: 1px solid var(--cSeparadores);
    border-right: 1px solid var(--cSeparadores); */
}


.main_default .table tr td:last-child {
    /* vertical-align: middle; */
    text-align: center;
}

.main_default .table tr td i,
.tableROE.table tr td i.fal {
    /* padding-right: 0.9rem; */
}

.main_default .table tr td a:last-child i {
    /* padding-right: 0; */
}

.formatDateTable {
    font-size: 60%;
    display: block;
}

.table thead th, .table td {
    vertical-align: middle;
    font-weight: inherit;
}

.pdaViewer_perfil .imgLogo {
    max-width: 200px;
    max-height: 200px;
    object-fit: cover;
}

.pdaViewer_policys .tituloClausula {
    display: block;
    font-style: italic;
    font-weight: 500;
}

.pdaViewer_policys .descripcionClausula {
    display: block;
    white-space: pre-line;
    padding: 0px 10px;
}

.pdaViewer_advance .titulo {
    font-weight: 500;
    font-size: inherit;
    display: block;
    margin-top: 1rem;
    margin-bottom: 0px
}

.z-badge {
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 0.25rem 0.5rem;
}

.x-btn.payments {
    color: var(--cSecundario); 
    background-color:var(--cSecundarioClaro2);
}
.x-btn.invoices {
    color: var(--cPrincipal); 
    background-color:var(--cPrincipalClaro);
}


.badge.principal{
    background-color: var(--cPrincipal);
    color:#fff;
}

.card .resumen-financial{
    list-style: none;
   
}

.card .card-body.resumen-financial{
    
    
    background-color: var(--cPrincipalClaro);
}

.resumen-financial ul{
    list-style: none;
    padding-left: 0px;
    margin-bottom: 0px;

}

.resumen-financial li{
   /* border-bottom: 1px solid var(--cSeparadores); */
    padding-top: 1rem;
    display: flex;
    justify-content: space-between;
}

.y-badge{
    border-radius: 0.25rem;
    padding: 0.25rem 0.5rem;
    width: 30px;
    height: 30px;
    align-items: center;
    display: flex;
    justify-content: center;
}

.y-badge.company{
    color: var(--cSecundario); 
    background-color:var(--cSecundarioClaro);
}

.y-badge.roe{
    color: var(--cCuartario);
    background-color: var(--cCuartarioClaro);
    width: auto;
    height: auto;
}

.y-badge.company,.y-badge.agency {
    color: var(--cSecundario); 
    background-color: var(--cSecundarioClaro2);
}

.y-badge.portcost{
    color: var(--cPrincipal); 
    background-color: var(--cSecundarioSelect);
}

.y-badge.owner{
    color: var(--cTerciario); 
    background-color: var(--cTerciarioClaro);
}

.y-badge.other {
    color: var(--cCuartario); 
    background-color: var(--cCuartarioClaro);
}

.p-inputtext::-webkit-input-placeholder {
    font-weight: 400;

}
.p-inputtext::-moz-placeholder {
    font-weight: 400;

}
.p-inputtext::-ms-input-placeholder {
    font-weight: 400;

}


.w250px {
    width: 250px !important;
}

.w50px {
    width: 50px !important;
}

.w100px {
    width: 100px !important;
}
.w150px {
    width: 150px !important;
}
.w200px {
    width: 200px !important;
    min-height: 200px !important;
}

.min-w100px{
    min-width: 100px !important;
}

.min-w150px{
    min-width: 150px !important;
}


.min-w250px {
    min-width: 250px !important;
}

.ddlStatus.p-dropdown {
    width: 150px;
}

.bkBase {
    background-color: #fff !important;
}

.table th,
.main_default th,
.p-datatable .p-datatable-thead>tr>th {
    background-color: transparent;
    min-width: 150px;
}

.p-datatable .p-datatable-thead>tr {
    background-color: var(--cPrincipalSelect);
}

.table th,
.main_default th,
.p-datatable .p-datatable-thead>tr>th:last-child {
    min-width: 50px;
}


/* Setting */


/*Formula*/

.header_add_item_formula {
    display: flex;
    align-items: center;
}

.header_add_item_formula .tipo {
    padding-right: var(--pGeneralLite);
    margin-right: var(--pGeneralLite);
    border-right: 1px solid var(--cSeparadores);
    line-height: inherit;
}

.header_add_item_formula .grupo {
    color: var(--cGrisClaro);
}

.main_setting_formulas .main_items_grupo_cuentas {
    height: 250px;
    overflow-y: auto;
}

.main_setting_formulas .main_cuentas {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid var(--cSeparadores);
    padding-block: 1rem;
}

.main_setting_formulas .main_cuentas span {
    padding-right: 1rem;
}

.main_setting_formulas .item_code {
    padding: 0.5rem;
    border: 1px solid var(--cSeparadores);
    border-radius: 5px;
    margin: 0.3rem;
    text-align: center;
    width: 100px;
}

.main_setting_formulas .item_code i.fal {
    padding-right: 0px;
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
}

.main_setting_formulas .item_code .code {
    font-weight: 500;
    display: block;
}

.main_setting_formulas .item_code .name {
    font-size: 0.8rem;
    color: var(--cGrisClaro);
}


/*bank*/

.main_setting_bank .titulo_intermediarios {
    margin-top: 0.5rem;
    margin-bottom: 1.5rem;
}



/* Team */

.main_setting_team .titulo_permisos .titulo_inside {
    margin-top: var(--espacioGeneralV);
    display: inline-block;
    cursor: pointer;
}

.main_setting_team .main_permission {
    transition: 1.5s;
    max-height: 0px;
    overflow: hidden;
}

.main_setting_team .open .main_permission {
    /* max-height: 500px; */
    max-height: 100%;
    /* overflow-y: auto; */
}

.main_setting_team .titulo_permisos>i {
    margin-left: var(--mIcono);
    transition: 1.5s;
}

.main_setting_team .open .titulo_permisos>i {
    transform: rotate(180deg);
}

.main_card_team {
    max-height: 600px;
    overflow-y: auto;
}



.main_setting_team .item_employed,
.main_setting_bank .item_bank,
.item_Policy {
    border-bottom: 1px solid var(--cSeparadores);
    padding: var(--espacioGeneralV) 0;
}

.item_Policy .left {
    display: flex;
    align-items: center;
    flex-direction: row;
}

.main_setting_team .item_employed .name,
.main_setting_bank .item_bank .name {
    font-weight: 500;
    color: var(--cFontTitulo);
    font-size: var(--fzTitulosInside);
    display: block;
}

.main_opciones{
    padding-inline: 5px;
}

.main_setting .main_opciones>.card,
.main_default .main_opciones>.card
{
    margin: 0px;
    border: none;
    background-color: transparent;
}

.main_setting .main_opciones .card-body,
.main_default .main_opciones .card-body {
    padding: 0.5rem 0;
}

.main_setting .main_opciones ul li a,
.main_default .main_opciones ul li a{
    color: var(--cFontTitulo);
    padding: 0.5rem;
    display: inline-block;
    cursor: pointer;
    white-space: nowrap;
}

.main_setting .main_opciones ul li a:hover,
.main_default .main_opciones ul li a:hover {
    text-decoration: none;
    /* background-color: var(--cBase); */
}

.main_setting .main_opciones ul li,
.main_default .main_opciones ul li {
    margin-right: var(--espacioGeneralH);
    padding-bottom: 0.5rem;
    border-bottom: 3px solid transparent;
    color: var(--cFontOscuro);
}

.main_setting .main_opciones ul li.select,
.main_default .main_opciones ul li.select,
.main_default .main_opciones ul li.selectChild {
    border-color: var(--cSecundario) !important;
}

.main_setting .main_opciones ul,
.main_default .main_opciones ul {
    list-style: none;
    display: flex;
    overflow-y: auto;
    margin-bottom: 0;
    padding-left: 0;
}


/* Profile */

.table thead th {
    /* font-size: var(--fzTitulosInside);
    font-weight: 500; */
}

.heightTeam.Max {
    height: 1680px !important;
    overflow-y: auto;
}

.heightTeam {
    transition: 1.5s;
}

.verMas {
    margin-top: var(--espacioGeneralV);
    display: flex;
    justify-content: center;
    font-weight: 500;
    font-size: 1.1rem;
    color: var(--cFontTitulo);
    line-height: normal;
}

.main_profile{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: normal;
    min-height: 100%;
    width: 100%;
    margin-block: 20px;
}

.main_profile th {
    border-top: none;
}

.circle_avatar {
    background-color: var(--cPrincipal);
    color: var(--cSecundario);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
}

.main_profile .circle_avatar {
    width: 85px;
    height: 85px;
    font-size: 2rem;
    font-weight: 500;
    padding: 25px;
}

.main_profile .card-team .actions {
    margin-top: var(--espacioGeneralV);
}

.main_profile .card-team .actions .titulo {
    font-size: inherit;
    font-weight: 500;
    margin-bottom: inherit;
}

.main_profile .card-team .actions .card-actions span {
    display: block;
}

.main_profile .card-team .actions .card-actions {
    padding: 10px 15px;
    border: 1px solid var(--cSeparadores);
    border-bottom: none;
}

.main_profile .card-team .actions .card-actions .cant {
    font-size: 1.25rem;
    color: var(--cFontTitulo);
}

.main_profile .card-team .actions .row:last-of-type .card-actions {
    border-bottom: 1px solid var(--cSeparadores);
}

.main_profile .card-team {
    margin: 1rem 0;
}

.main_profile .info span {
    display: block;
}



.main_profile .card {
    display: inline-block;
    margin: 1rem 0;
    width: 100%;
}

.card-body {
    padding: var(--pCardGeneral);

}

.card-body:not(:only-child(.main_opciones)){
    overflow: auto;
}

.main_info_company .info_top .left,
.main_my_info .info_top .left {
    margin-top: var(--espacioGeneralV);
    display: flex;
    flex-direction: column;
    align-self: center;
    font-weight: 500;
}

.main_info_company .info_top .left .titulo,
.main_my_info .info_top .left .titulo {
    font-weight: 500;
    font-size: 1.25rem;
    color: var(--cFontTitulo);
    margin-bottom: 0px;
}

.main_info_company .info_top .left .sub_titulo,
.main_my_info .info_top .left .sub_titulo {
    color: var(--cGrisClaro);
}

.main_info_company .info_bottom,
.main_my_info .info_bottom {
    margin-top: var(--espacioGeneralV);
}

.main_info_company .info_bottom .info span,
.main_my_info .info_bottom .info span,
.main_default .info span,
.main_pda_viewer .info span,
.pdaViewer_perfil .info span,
.main_toAcceptAppointment .info span {
    display: block;
    /* overflow: hidden;
    text-overflow: ellipsis; */
}

.info {
    margin-bottom: var(--espacioGeneralV);
}

.info .name {
    color: var(--cFont);
    display: block;
}
.info .data {
    font-weight: 500;
}

.main_pda_viewer .table:not(.tableSumary) tbody tr:not(:last-child) td:last-child {
    text-align: center;
}

.main_pda_viewer .table tbody tr:last-child td:first-child {
    /* border: none; */
}

.rounded-pill.pill-act-dischargind.small{
    font-size: 0.75em;
}
.rounded-pill.pill-act-dischargind.small i{
    font-size: 0.90em;
}

.main_info_company .logo {
    width: 100px;
    height: 100px;
    object-fit: contain;
}

.titulo {
    font-weight: 400;
    font-size: 1.4rem;
    color: var(--cFontTitulo);
    margin-bottom: 0.8rem;
    display: inline-block;
    line-height: normal;
}

.titulo_inside {
    font-weight: 500;
    font-size: var(--fzTitulosInside);
    color: var(--cFontTitulo);
    display: block;
    line-height: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}



/* Empresas */

.main_bussines .card {
    margin-bottom: 2rem;
}

.main_bussines .header {
    margin-bottom: 2rem;
}

.main_bussines .header .section_left .titulo {
    color: var(--cFontTitulo);
    font-size: var(--fzTitulos);
    line-height: normal;
    font-weight: normal;
}

.main_bussines .header .section_left .form-group {
    margin-bottom: 0px;
}

.main_card_empresa {
    padding: var(--pCardGeneral);
}

.main_card_empresa .main_logo .logo {
    width: 120px;
    height: 120px;
}

.main_card_empresa .sub_info {
    margin-top: 1rem;
}

.main_card_empresa .card_empresa_info {
    padding-left: 1.25rem;
    flex-grow: 1;
    font-size: var(--fzGeneral);
}

.main_card_empresa .card_empresa_info .nombre_empresa {
    color: var(--cFontTitulo);
    font-size: var(--fzTitulos);
}

.main_card_empresa .sub_nombre_empresa {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.main_card_empresa .sub_nombre_empresa span {
    padding-right: 1.25rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

i.fal:not(.fa-spin),
i.fa:not(.fa-spin) {
    /* padding-right: 0.5rem; */
}

.main_card_empresa .info_date div:not(:last-child) {
    margin-right: 1.25rem;
}

.main_card_empresa .info_date div span {
    display: block;
}

.main_card_empresa .bottom {
    display: flex;
    flex-wrap: wrap;
}

.main_card_empresa .main_item {
    display: flex;
    justify-content: center;
    margin: 0px 1.1rem;
}

.main_card_empresa .main_item i {
    display: flex;
    font-size: 2rem;
}

.main_card_empresa hr {
    margin: 1.5rem 0px;
}

.main_card_empresa .main_item .cant {
    line-height: 0.5;
}

.main_card_empresa .main_item .cant,
.info_closing .cant {
    display: block;
    font-weight: 400;
    font-size: var(--fzTitulos);
    color: var(--cFontTitulo);
}

.info_closing .cant {
    line-height: normal;
}

.d_boton_azul {
    padding: 0.5rem;
    background-color: var(--cPrincipalSelect);
    color: var(--cPrincipal);
    font-weight: 500;
    border-radius: 5px;
    text-align: center;
}

.d_boton_secundario {
    padding: 0.5rem;
    background-color: var(--cSecundarioSelect);
    color: var(--cSecundario);
    border-radius: 5px;
    font-weight: 500;
    text-align: center;
}

.badge_search {
    background-color: var(--cSecundarioSelect);
    color: var(--cSecundario);
    min-width: 50px;
    padding: 10px;
    margin-bottom: 1rem;
    font-weight: 500;
    font-size: var(--fzGeneral);
}

.form-check{
    display: flex;
    align-items: center;
}
.form-check .form-check-label{
   margin-left: 5px;
}

.form-check-input{
    margin-top: unset;
}

.bagde_principal,
.statusViaje {
    background-color: var(--cPrincipalSelect);
    color: var(--cPrincipal);
    font-weight: 500;
    font-size: var(--fzGeneral);
}


/* .main_pending_apppointment .statusViaje {
    padding: .4rem .6rem !important;
    margin-left: var(--espacioGeneralH);
    background-color: var(--cPrincipal);
    font-weight: 500;
    color: #fff
} */


/* fin */




.noBorderBottom {
    border-bottom: 0px;
}

.ng-star-inserted {
    white-space: initial;
}




/* .form-check-input {
    margin-top: 0.26rem;
} */

[data-ladda] .icon_ladda {
    display: block;
}

[data-ladda] .text_ladda {
    display: none;
}

.icon_ladda {
    display: none;
    line-height: inherit;
}

.text_ladda {
    display: block;
}

/* .main_login .btn-secundario,
.main_loginPDA .btn-secundario {
    color: var(--cBtn);
    background-color: var(--cSecundario);
} */

/* .main_login .btn-secundario:hover,
.main_loginPDA .btn-secundario:hover {
    background-color: var(--cSecundario);
} */

.btn-secundario {
    background-color: var(--cSecundario);
    color: var(--cBtn);
    /* backgroundcPriicipali-ipalicipaliipalicipallcipalor: var(--cSecundario); */
}

.btn-secundario:hover {
    color: var(--cPrincipal);
    
}

.btn:active, .btn:focus{
    outline: none;
    box-shadow:none;
}

.btn-principal,
a:not([href]).btn-principal {
    color: var(--cBtn);
    background-color: var(--cPrincipal);
}

.link-principal{
    color: var(--cPrincipal);
    font-weight: 500;
}

.link-principal:hover{
    text-decoration: underline;
}

.btn-cancel {
    border: 1px solid var(--cSeparadores);
    color: var(--cGray);
    background-color: #fff;
}

.btn-principal:hover {
    color: #fff;
}

.x-btn.btn-principal:hover{
    background-color: var(--cPrincipal);
}

.btn-principal:hover {
    color: #fff;
}

.add_empresa_main_planes .card_planes {
    border: 1px solid var(--cSeparadores);
    cursor: pointer;
}

.add_empresa_main_planes .card_planes.select {
    background-color: var(--cSecundarioSelect);
}

.add_empresa_main_planes .card_planes label {
    display: block;
}

.add_empresa_main_planes .card_planes {
    padding: 1rem 1rem;
}

.add_empresa_main_planes .titulo {
    font-size: 1rem;
    font-weight: bold;
}

.add_empresa_main_planes .card_planes .titulo {
    font-weight: bold;
    font-size: 1rem;
    margin-bottom: 0;
}

.add_empresa_main_planes .card_planes .sub_titulo {
    color: var(--cGrisClaro);
}

.add_empresa_main_planes .card_planes .descripcion {
    margin-top: 1rem;
    color: var(--cGrisClaro);
}

.add_empresa_main_planes .card_planes input.qty {
    width: 70px;
}


/*Mat*/

.mat-stepper-horizontal form {
    padding: 2rem 0rem;
}


/*Formulario*/

.addlogo img {
    border: 1px solid var(--cGrisClaro);
    width: 120px;
    height: 120px;
    object-fit: contain;
}

.addlogo span {
    font-weight: bold;
}


/* menu_open */

.menu_content {
    position: fixed;
    height: 100%;
}

.menu_content .subMenuContent {
    display: none;
}

.menu_content .subMenuContent.select {
    /* height: 100%; */
    display: inherit;
}

.subMenuContent li.select {
    background-color: var(--cSelectSubMenu);
}

.menu_content {
    /* display: hidden; */
    margin: inherit;
    min-height: 100vh;
    /* width: var(--wMenuClose); */
    background-color: #fff;
    margin-left: calc(var(--mMenuContentCloseXS));
    width: var(--wMenuOpen);
    top: 0;
    padding-left: 0px;
    z-index: 5;
    transition: 1.5s;
    -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
}

.menu_content_titulo {
    font-size: 2rem;
}

.menu_content .menu_content_header {
    justify-content: start;
    padding: 18px var(--pGeneral);
    border-bottom: 1px solid var(--cSeparadores);
}

.menuOpen .menu_visibleXS .menu_content {
    margin-left: var(--wMenuClose);
}

.menuOpen .main_content {
    margin-left: initial;
}

.menu_content ul {
    list-style: none;
    margin: 0px;
    padding: 0px;
}

.menu_content li {
    padding: 12px var(--pGeneral);
    list-style: none;
    margin: 0px;
    font-size: 0.9rem;
}

.menu_content li a {
    color: var(--cGrisClaro);
    text-decoration: none;
    display: block;
}

.menu_content li:hover {
    background-color: var(--cBase);
}

.menu_content li a:hover {
    background-color: var(--cBase);
    text-decoration: none;
}

.menu_content li.select {
    color: var(--cMainMenu);
    background-color: var(--cBase);
    font-weight: 400;
}


/* content */

.content {
    overflow: auto;
    position: relative;
    background-color: var(--cBase);
    /* height: calc(var(--viewport-height) - var(--hHeader)); */
    /* min-height: calc(100svh - var(--hHeader)); */
    padding: 0px var(--pGeneral) 2rem;
    z-index: 1;
    /* height: 100%; */
    /* height: calc(100svh - var(--hHeader)); */
    
    /* display: flex; */
}

.outer{
    width: 100vw;
    max-width: 100%;
    min-height:100svh;
    height: 100svh;
}
/* 
.section-full-screen {
    width: 100vw;
    max-width: 100%;
    height: calc(100vh - var(--header-height));
    height: calc(100svh - var(--header-height));
    min-height: calc(100vh - var(--header-height));
    min-height: calc(100svh - var(--header-height));
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    position: relative;
  } */

.main_page_titulo {
    display: flex;
    align-items: center;
    overflow: hidden;

}


.page_titulo h1{
    font-size: 20px;
    font-weight: 400;
    margin-bottom: 0px;
    padding-bottom: 2px;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.page_sub_titulo h2{
    font-size: 13px;
    font-weight: 400;
    color: var(--cGrisClaro);
    margin-bottom: 0px;
}

.page_titulo {
    padding-right: var(--pGeneralLite);
    border-right: 1px solid var(--cSeparadores);
    margin-right: var(--pGeneralLite);
    min-width: 0;
}

hr {
    color: var(--cSeparadores);
    opacity: 1;
}

.content hr {
    margin-bottom: 1.5rem;
}


/* header */

.main_header {
    background-color: #fff;
    height: var(--hHeader);
    /* position: sticky; */
    -webkit-backface-visibility: hidden;
    top: 0;
    display: flex;
    /* width: calc(100% - var(--wMenuClose)); */
    padding: 18px var(--pGeneral);
    z-index: 4;
    box-shadow:  0 2px 5px 0 rgba(0, 41, 69, 0.10);
}

.main_header.portalConsulta{
    background-color: var(--cPrincipal);
}


.dropdown-menu {
    font-weight: 300;
}

.dropdown-menu a {
    padding-top: 12px;
    padding-bottom: 12px;
    font-size: var(--fzGeneral);
    color: var(--cFont);
    line-height: normal;
}

.dropdown-menu a i {
    font-size: 1rem;

}

.menu_content .header_logo{
    width: calc(var(--wMenuOpen) - 60px);
    height: auto;
}

.main_header .header_logo {
    width: 80px;
    height: auto;
}

.main_header .header_right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-left: auto;
    line-height: normal;
}

.header_avatar_usuario {
    /* background-color: var(--cGrisClaro); */
    /* background-color: var(--cPrincipalClaro); */
    border: 1px solid;
    color: var(--cSecundario);
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    font-size: 0.8rem;
    font-weight: 500;
}

.header_right .dropdown {
    margin-left: 1rem;
}

.header_right .dropdown .toogle {
    font-size: 14px;
    margin-left: 5px;
}

.header_right .icon {
    font-size: 20px;
}

.header_right .header_nombre {
    display: none;
}

.main_header a.icon {
    /* color: var(--cGrisClaro); */
}

.header_right .dropdown-item.active,
.dropdown-item:active {
    /* background-color: var(--cSecundario); */
    background-color: var(--cPrincipal);
    color: var(--cBase) !important;
}

.p-relative{
position: relative;
}

a.dropdown-item {
    cursor: pointer;
}

.dropdown-item.logout{
    border-top:1px solid;
    border-color: var(--cSeparadores);
}


/* main */

.allheightContent{
    height: calc(100vh - var(--hHeader));
}

.main_menu .main_menu_header i.fal,
.main_menu li i.fal {
    padding-right: 0px;
}

.main_menu a:not([href]):hover {
    color: var(--cBase);
}

.main_menu ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.main_menu li a i {
    color: var(--cBtn);
}

.main_menu li {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    /* border-top: 0.2px solid var(--cSeparadores); */
    font-size: 24px;
}

.main_menu li.select {
    border-left: 1.5px solid var(--cSelectMainMenu);
    background-color: var(--cPrincipalSelect);
}

.main_menu li.select a i {
    color: var(--cSelectMainMenu);
}

.main_menu_header,
.menu_content_header {
    height: var(--hHeader);
    display: flex;
    align-items: center;
    justify-content: center;
}

.btn_open_menu.min {
    font-size: 20px;
    margin-right:  20px;
}

.dropdown-menu i{
    margin-right: 16px;
}

.btn_open_menu {
    font-size: 20px;
}

.btn_open_menu:hover,
.main_menu_header a:not([href]).btn_open_menu {
    color: #fff;
}

.row_main {
    display: flex;
}

.main_app {
    background-color: var(--cBase);
}

.main_menu {
    background-color: var(--cMainMenu);
    width: var(--wMenuClose);
    height: 100%;
    margin: inherit;
    position: fixed;
    text-align: center;
    padding: inherit;
    color: var(--colorBtn);
    z-index: 6;
    top: 0;
}

.main_content {
    width: 100%;
    /* margin-left: var(--wMenuClose); */
}

.main_info{
    display: flex;
    height: calc(var(--viewport-height) - var(--hHeader));
    background-color: #fff;
}

.main_info.main_dash,.main_info.main_book{
    height: calc(var(--viewport-height) - var(--hHeader));
}

.main_info > .card{
    width: 100%;
}

.info-callInvitation li{
    margin-bottom: 1rem;
}


/* .menuOpen .main_content {
    max-width: calc(100vw - (calc(var(--wMenuOpen) - var(--wMenuClose))));
    width: inherit;
} */


/* login */




.main_loginPDA {
    background: linear-gradient(var(--cSecundario), black);
    max-width: 100vw;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 30px;
    /* color: #fff; */
}

.form-group {
    text-align: left;
}

.main_login hr {
    width: 100%;
    border-color: #fff;
}

/* .main_login .titulo,
.main_loginPDA .titulo {
    font-size: 2rem;
    margin-bottom: 0px;
    font-weight: 300;
    color: #fff;
} */



.main_login .login_left {
    border-right: 1px solid #fff;
    padding-right: 5rem;
}



.main_login .card-body,
.main_loginPDA .card-body {
    padding: 3rem 3rem 2rem 3rem;
}

.main_login>.container.row,
.main_loginPDA>.container.row {
    height: 100vh;
}

.main_txt_icon {
    position: relative;
    display: flex;
    align-items: center;
}

.main_txt_icon .icon {
    position: absolute;
    left: 10px;
    color: var(--cGrisClaro);
    font-size: 20px;
    padding-top: 2px;
}

.main_txt_icon .icon.md {
    font-size: 1rem;
}

.main_txt_icon .icon.right {
    right: 10px;
    left: inherit;
}

.pointer {
    cursor: pointer;
}

.main_login .main_txt_icon input,
.main_login .main_txt_icon textarea,
.main_login .main_txt_icon select,
.main_loginPDA .main_txt_icon input,
.main_loginPDA .main_txt_icon textarea,
.main_loginPDA .main_txt_icon select,
.form_with_icon_left
{
    padding-left: 40px !important;
}




.main_login .logo,
.main_loginPDA .logo {
    margin: 0rem 0 2rem 0px;
    width: 242px;
    margin-right: 1rem;
}

.main_login .logoSM,
.main_loginPDA .logoSM {
    margin-bottom: 2rem;
}

.main_login .isoTipo,
.main_loginPDA .isoTipo {
    width: 25px;
    height: auto;
}

.main-list-items-fda{
    max-height: 300px;
}

.main-comment-upload-fda, .main-file-upload-fda{
    min-height: 300px;
}

/* Extra small devices (portrait phones, less than 576px) */

@media (max-width: 575.98px) {

    .card_voyages.cargo-report .card-body .x-border{
        border-right: none;
        border-left:none;
    
    }
}


/* Small devices (landscape phones, 576px and up) */

@media (min-width: 576px) {
    .alert .msgAlert {
        /* padding-left: 0px;*/
        align-items: center;
        flex-direction: row;
        justify-content: start;
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {
    .main_login .login_right {
        padding-left: 15px;
    }
    
    .main_login .logo,
    .main_loginPDA .logo {
        min-width: 350px;
        /* margin-right: 20px; */
    }
}

@media (max-width: 767.98px) {


    /* .main_info>.card{
        padding-bottom: 4rem;
    } */
    
    /* .outer{
        padding-bottom: 2rem;
    } */

    .mat-horizontal-content-container{
        padding:0 !important
    }

    .content{
        padding-inline: 0;
        padding-bottom: 0;
       
    }

    /* .main_info.main_dash, .main_info.main_book{
        height: calc(100vh - var(--hHeader) - 8rem) !important;
    } */

    /* .main_info{
        height: calc(100vh - var(--hHeader) - 10rem) !important;
    }

    .x-scroll{
        height: calc(100vh - var(--hHeader) - 10rem);
    } */

    /* .content {

        min-height: calc(100vh - var(--hHeader) - 2rem);
        min-height: calc(100svh - var(--hHeader) - 2rem);
        height: 100%;
        height: calc(100svh - var(--hHeader) - 2rem);
    } */


    .card-body-main,.card-body{
        padding-inline: .75rem;
    }
    .card-body-dash{
        padding-inline: 0;
    }

    .login_section{
        min-height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .btn-sm-block {
        display: block;
        width: 100%;
    }
    .main_header {
        /* padding-left: var(--pGeneral);
        padding-right: var(--pGeneral); */
        padding-inline: 15px;
    }
    .menu.menu_visibleXS .main_menu {
        margin-left: 0px;
    }
    .menu .main_menu {
        position: fixed;
        transition: 1.5s;
        margin-left: calc(0px - var(--wMenuClose));
    }
    .main_bussines .header>*,
    .main_bussines .header .section_left>* {
        margin-top: 1rem;
    }
    .main_bussines .header .btn {
        display: block;
        width: 100%;
    }
    .card_empresa_info .info_date>div,
    .main_card_empresa .card_empresa_info {
        margin-top: 1rem;
    }
    .main_card_empresa .main_item {
        margin-top: 1rem;
    }
}


/* Medium devices (tablets, 768px and up) */

@media (min-width: 768px) {





    .border-md-inline{
        border-inline: 1px solid var(--cSeparadores);
    }

    .card_voyages.cargo-report .card-body .x-border{
        border-right: 1px solid;
        border-left: 1px solid;
        border-color:rgba(0,0,0,.125);
    }

    .table.pda .col-valor{
        width: 200px;

    }
    
    
    .table.pda .ultColum{
        width: 100px;
    }

    .table.pda .col-opt{
        width: 150px;
    }
    .card_voyages_left {
        border-right: 1px solid var(--cGrisClaro);
    }
    .card_Appointments .borderRight {
        border-bottom: none;
        border-right: 2px solid;
    }
    .list-md-inline-item {
        display: inline-block;
    }
    .main_pending_apppointment .list-md-inline-item span.tituloItem {
        display: block;
    }
    
    .w-md-50 {
        width: 50%;
    }
    .separadorRigth {
        border-right: 1px solid var(--cSeparadores);
    }
    .menu_content .subMenuContent.select {
        position: sticky;
        top: 0;
    }
    .alert .close {
        align-items: center;
    }
    .main_login .login_form,
    .main_loginPDA .login_form {
        padding: 1.5rem;
    }
    .heightTeam.Max {
        height: 680px !important;
        overflow-y: auto;
    }
    .heightTeam {
        height: 340px;
        transition: 1.5s;
    }
    .main_profile .info {
        padding-left: var(--espacioGeneralH);
    }
    .main_profile .card-team .actions .row .card-actions {
        border: 1px solid var(--cSeparadores);
    }
    .main_profile .card-team .actions .row:first-of-type .card-actions {
        border-bottom: none;
    }
    .main_profile .card-team .actions .row:first-of-type .card-actions:first-of-type {
        border-right: none;
    }
    .buscadorAnim.form-control {
        width: 300px;
        transition: 1.5s;
    }
    .buscadorAnim.form-control:focus {
        width: 400px;
    }
    .main_info_company .info_top,
    .main_my_info .info_top {
        margin-top: 0.5rem;
    }
    .main_info_company .info_top .left,
    .main_my_info .info_top .left {
        margin-top: 0px;
        padding-left: var(--espacioGeneralH);
    }
    .menuOpen .menu_content {
        margin-left: var(--wMenuClose);
    }
    .main_header .header_logo {
        width: 150px;
        height: auto;
    }
    .menu_content {
        margin-left: calc(var(--mMenuContentClose));
        position: sticky;
    }
    .header_right .header_nombre {
        display: inline;
        margin-left: var(--mIcono);
        max-width: 150px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    .main_header.pdaViewer {
        height: inherit;
        background-color: var(--cPrincipal);
    }

    .main_header .header_up {
        padding: 18px var(--pGeneral);
        align-items: center;
    }

    .main_header .header_down {
        background-color: var(--cSecundarioClaro);
        padding: 10px var(--pGeneral);
        color:var(--cPrincipal)
    }

    .header_down .titulo{
        font-weight: 500;
        margin-bottom: 0px;
        color: var(--cPrincipal);
    }

    .header_down .nombreEmpresa{
        font-weight: 500;
    }
    .main_header .header_up ul {
        padding: 10px 0px;
        margin: 0px;
        display: flex;
        justify-content: flex-end;
    }
    .main_header .header_up li {
        list-style: none;
        display: inline;
        padding: 0 20px;
    }
    .main_header .header_up li a {
        color: #fff;
        font-style: normal;
    }
    .main_header .header_up li:hover a {
        color: #fff;
        text-decoration: none;
    }
    .main_bussines .header .section_left .form-control {
        width: 200px;
        transition: 1.5s;
    }
    .main_card_empresa .bottom {
        justify-content: center;
    }
    .main_card_empresa .info_date .progress {
        width: 300px;
    }
    .main_card_empresa .top {
        display: flex;
    }
    .mat-stepper-horizontal form {
        padding: 2rem 3rem;
    }
}

@media (max-width: 991.98px) {

    .x-input .icon{
        display: none;
    }
    .x-label{
        width: 100px;
    }

    .maximizarSM {
        width: 100vw;
        height: 100vh;
        padding: 0px;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    .wDialogSM {
        width: 350px;
    }
    .wDialogLG {
        width: 85vw;
    }
    .wDialogMD {
        width: 50vw;
    }
}


/* Large devices (desktops, 992px and up) */

@media (min-width: 992px) {

    .main-list-items-fda{
        position: absolute;
        max-height: unset;
    }
    
    .main_list_note {
        height: 100px;
    }

    .x-vs-container-inputs .mat-datepicker-input{
        width: 100px;
    }
    
    .x-vs-container-inputs .time{
        width: 150px;
        display: flex;
        align-items: center;
    }

    .x-input .icon{
        border-radius: 50%;
        width: 28px;
        height: 28px;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        border: 1px dashed rgba(176, 190, 197, 0.8);
        display: flex;
    }
    
    .x-input .icon i{
        color:  rgba(176, 190, 197, 0.8);
    }


    .p-dropdown.p-component::before, .p-multiselect.p-component::before{
        border-radius: 50%;
        width: 28px;
        height: 28px;
        align-items: end;
        justify-content: center;
        font-size: 16px;
        border: 1px dashed rgba(176, 190, 197, 0.8);
        color: rgba(176, 190, 197, 0.8);
        /* margin-right: 0.75rem; */
        content: "\f7a4";
        font-family: "Font Awesome 5 Pro";
        display: flex;
        flex-shrink: 0;
        font-weight: 400;
        
    }

    .border-right-fda-portal{
        border-right: 1px solid var(--cSeparadores);
    }

    .border-fda-portal{
        border-left: 1px solid var(--cSeparadores);
        border-right: 1px solid var(--cSeparadores);

    }

    .row-vesselStatus{
        border-bottom: 1px solid var(--cSeparadores);
        margin-bottom: 1rem;
    }
    
    .row-vesselStatus .x-vs-container-button{
        margin-top:1rem;
        border-right: 1px solid var(--cSeparadores);
        padding-right: 1.5rem;
        align-self: flex-start;
       
    }
    
    .row-vesselStatus .x-vs-container-inputs{
        padding-left: 1.5rem;
       
    }




    .header_up .main_menu_header {
        display: none;
    }
    .p-inputwrapper-filled {
        display: inline;
    }
    .p-paginator {
        justify-content: flex-end !important;
    }
    .w-30 {
        width: 30%;
    }

    .main_default .table th,
    .tdOptionItem {
        /* min-width: inherit;
        max-width: inherit;
        width: 250px; */
    }
    .main_default .table th:Last-child {
        /* min-width: inherit;
        max-width: inherit; */
        /* width: 150px; */
    }
    .main_default .table th:first-child {
        /* min-width: inherit;
        max-width: inherit;
        width: auto; */
    }
    .main_default .table.tableSumary th:first-child {
        min-width: inherit;
        max-width: inherit;
        width: 250px;
    }
    .w-20 {
        min-width: inherit !important;
        max-width: inherit !important;
        width: 20%;
    }
    
    .wDialogSM {
        width: 450px;
    }

    .wDialogMD {
        width: 550px;
    }

    .wDialogLG {
        width: 850px;
    }

    .wDialogXL {
        width: 1050px;
    }

    .main_login .logo,
    .main_loginPDA .logo {
        min-width: 350px;
    }
    .main_login .login_right {
        padding-left: 5rem;
    }
}

@media (min-width: 992px) and (max-width: 1199.98px) {}


/* Extra large devices (large desktops, 1200px and up) */

@media (min-width: 1200px) {
    .previewPDF {
        height: 700px;
    }
}

@media (max-width: 1200px) {}

@media (min-width: 1500px) {
    .previewPDF {
        height: 2000px;
    }
}

